import React, { createContext, useContext, useState } from 'react';

const ConfirmationDialogContext = createContext();

export const useConfirmationDialog = () => useContext(ConfirmationDialogContext);

export const ConfirmationDialogProvider = ({ children }) => {
    const [text, setText] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [callbackFunc, setCallbackFunc] = useState(null);

    const showDialog = (text, callback_func) => {
        setText(text);
        setIsVisible(true);
        setCallbackFunc(() => { return callback_func })
    };

    const handleConfirmButton = () => {
        callbackFunc();
        setIsVisible(false);
    };

    const handleCancelButton = () => {
        setCallbackFunc(null);
        setIsVisible(false);
    };

    return (
        <ConfirmationDialogContext.Provider value={{ text, isVisible, showDialog, handleConfirmButton, handleCancelButton }}>
            {children}
        </ConfirmationDialogContext.Provider>
    );
};