import React from 'react';
// import ConfirmationDialog from '../components/ConfirmationDialog.js';

const Test = () => {

    function handle1TestButtonClick() {
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications!");
        } else if (Notification.permission === "granted") {
          // Если разрешение уже предоставлено, можно отправлять уведомления
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    console.log("Notification permission granted.");
                }
            });
        }
    }

    const handle2TestButtonClick = () => {
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
        } else if (Notification.permission === "granted") {
            const title = 'Wow'
            const options = {
                body: 'Noti body',
                
            }
            new Notification(title, options);
        }
    }

    

    // const handle3TestButtonClick = () => {
    //     openDialog("123", )
    // }

    // function openDialog(text, target_func) {
    //     if (confirmationDialogRef.current){
    //       confirmationDialogRef.current.showDialog(text, target_func)
    //     }
        
    //   }

    return (
        <div className="Test">
            <button className="kitsunet-design-input-button" onClick={handle1TestButtonClick}>Request perm</button>
            <button className="kitsunet-design-input-button" onClick={handle2TestButtonClick}>Send test</button>
            {/* <button className="kitsunet-design-input-button" onClick={handle3TestButtonClick}>Confirmation dialog</button> */}
        </div>
    );
};

export default Test;