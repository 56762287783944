import "./Auth.css";
import { API_SERVER } from "./config.js";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NotificationComponent from "./components/NotificationComponent.js";
import Cookies from "js-cookie";

import HeaderTailIcon from "./assets/images/main-page-tail.svg";
import KitsunetIcon from "./assets/images/kitsunet.png";

import ForwardArrowIcon from "./assets/images/arrow_forward.svg";
import BackArrowIcon from "./assets/images/arrow_back.svg";
import LeftEarIcon from "./assets/images/left-ear.svg";
import RightEarIcon from "./assets/images/right-ear.svg";
import LeftOverEarIcon from "./assets/images/left-ear-cropped.svg";
import RightOverEarIcon from "./assets/images/right-ear-cropped.svg";

function Auth() {
  const notificationRef = useRef();

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [containerActive, setContainerActive] = useState(false);
  const [labelForAuth, setLabelForAuth] = useState(
    "Введите почту для авторизации"
  );

  function showNotification(title, text) {
    if (notificationRef.current) {
      notificationRef.current.showNotification(title, text);
    }
  }

  useEffect(() => {}, []);

  const handleSubmitEmail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/auth/request_code`,
        { email }
      );
      if (
        response.data.detail === "Login" ||
        response.data.detail === "Register"
      ) {
        setContainerActive(true);
        setActiveStep(1);
        console.log(response.data.detail);
        if (response.data.detail === "Login") {
          setLabelForAuth(
            "Введите код, отправленный в ваше приложение и на вашу почту"
          );
        } else {
          setLabelForAuth("Введите код, отправленный на вашу почту");
        }
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitCode = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/auth/authentificate`,
        { email, code }
      );
      if (response.data.token) {
        Cookies.set("authToken", response.data.token, {
          expires: 120,
          sameSite: "strict",
        });
        window.location.reload();
        console.log("Auth done");
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    if (email !== "") {
      handleSubmitEmail();
    }
  };

  const handlePrevStep = () => {
    setLabelForAuth("Введите почту для авторизации");
    setActiveStep(0);
  };

  useEffect(() => {
    const parallaxElements = document.querySelectorAll(".parallax-element");

    document.addEventListener("mousemove", (event) => {
      const mouseX = event.clientX / window.innerWidth;
      const mouseY = event.clientY / window.innerHeight;

      parallaxElements.forEach((element) => {
        const elementRect = element.getBoundingClientRect();
        const xSpeed = (mouseX - elementRect.x / window.innerWidth) * 50;
        const ySpeed = (mouseY - elementRect.y / window.innerHeight) * 50;

        if (element.classList.contains("over")) {
          element.style.transform = `translate(${xSpeed*1.5}px, ${ySpeed*1.5}px)`;
        } else {
          element.style.transform = `translate(${xSpeed}px, ${ySpeed}px)`;
        }
      });
    });
  }, []);

  return (
    <div className="Auth">
      <div id="auth-page-indicator"></div>

      <NotificationComponent ref={notificationRef} />

      <img src={HeaderTailIcon} className="header-tail" />
      <h1 className="header-logo ">Kitsunet</h1>

      <div className="about-container">
        <div className="about-text-holder">
          <h2>Что это?</h2>
          <h3>
            Kitsunet - это универсальная онлайн платформа, объединяющее
            различные функции для комфортного взаимодействия в интернет-среде
          </h3>
        </div>
      </div>

      <div className="auth-form">
        <div>
          <h2>Добро пожаловать</h2>
          <h3>{labelForAuth}</h3>
        </div>
        <div
          className={`slide-form-container ${
            activeStep === 0 ? "step-1-active" : "step-2-active"
          }`}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-wrapper">
            <form className={`email-form ${activeStep === 0 ? "active" : ""}`}>
              <input
                className="auth-input-field"
                placeholder="Почта"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="email-continue-button-container">
                <button
                  className="email-continue-button"
                  onClick={handleNextStep}
                  disabled={isLoading}
                >
                  <img src={ForwardArrowIcon} />
                </button>
              </div>
            </form>
            <form
              className={`code-form ${activeStep === 1 ? "active" : ""}`}
              onKeyDown={(e) => e.key === "Enter" && handleSubmitCode()}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className="auth-input-field"
                placeholder="Код"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
              <div className="email-continue-button-container">
                <button
                  className="email-continue-button"
                  onClick={handlePrevStep}
                  disabled={isLoading}
                >
                  <img src={BackArrowIcon} />
                </button>
                <button
                  className="email-continue-button"
                  onClick={handleSubmitCode}
                  disabled={isLoading}
                >
                  <img src={ForwardArrowIcon} />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <footer className="footer-deco">
        <div className="right-ear-holder">
          <a></a><img src={RightEarIcon} className="parallax-element"></img>
          <img src={RightOverEarIcon} className="parallax-element over"></img>
        </div>
        <div className="left-ear-holder">
          <img src={LeftEarIcon} className="parallax-element"></img>
          <img src={LeftOverEarIcon} className="parallax-element over"></img>
        </div>
      </footer>
    </div>
  );
}

export default Auth;
