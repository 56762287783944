import React, { forwardRef } from 'react';
import './ConfirmationDialog.css';
import { useConfirmationDialog } from '../context/ConfirmationDialogContext'; // Импортируем наш контекст

const ConfirmationDialog = forwardRef((props, ref) => {
    const { text, isVisible, handleConfirmButton, handleCancelButton } = useConfirmationDialog(); // Получаем значения из контекста

    return (
        <div className="ConfirmationDialog" ref={ref} style={{ display: isVisible ? 'flex' : 'none' }}>
            <div className="dialog-container">
                <div className="dialog-container-info">
                    <h1 id="dialog-title">Точно?</h1>
                    <p id="dialog-text">{text}</p>
                </div>
                <div className="dialog-container-buttons">
                    <button className="dialog-container-buttons-cancel kitsunet-design-input-button" onClick={handleCancelButton}>Отмена</button>
                    <button className="dialog-container-buttons-confirm kitsunet-design-input-button" onClick={handleConfirmButton}>Да</button>
                </div>
            </div>
        </div>
    );
});

export default ConfirmationDialog;