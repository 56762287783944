import React from 'react';
import './Search.css';
import { STORAGE_SERVER } from '../config.js'

const Search = ({searchResults, handleSearchChange, request_page, switch_tab}) => {

    return (
        <div className="Search">
            <h2 className="search-title">Поиск</h2>
            <div className="search-bar-container">
                <input id="search-bar-input" className="kitsunet-design-input-field" type="text" onChange={handleSearchChange}/>
            </div>
            <div className="search-users-container">
                {searchResults.map(user => (
                    <div key={user.id} className="search-users-user-container" onClick={() => {
                    request_page(user.id);
                    switch_tab('other-user');
                    }}>
                    <div className="search-users-user-avatar">
                        <img className="search-users-user-avatar-src" src={`${STORAGE_SERVER}avatars/${user.avatars?.split(";")[user.avatars?.split(";").length - 1] || 'avatar_placeholder.png' }`} alt='USER_AVATAR' />
                    </div>
                    <div className="search-users-user-text">
                        <h3 className="search-users-user-name">{user.display_name || user.username}</h3>
                        <h4 className="search-users-user-description">{user.description || 'No description available.'}</h4>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
};

export default Search;