import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const requestPermission = async () => {
        try {
            await Notification.requestPermission();
            console.log("Notification permission granted.");
        } catch (error) {
            console.error("Error requesting notification permission:", error);
        }
    };

    const sendNotification = (title, text) => {
        if (Notification.permission === "granted") {
            console.error(text);


            new Notification(title, {body: text});
        } else {
            console.log("Permission not granted");
            requestPermission();
        }
    };

    return (
        <NotificationContext.Provider value={{ notifications, requestPermission, sendNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};