import React, { useState, useEffect } from 'react';
import {isMobile} from 'react-device-detect';
import Cookies from 'js-cookie';

import './App.css';

import Main from './Main';
import Auth from './Auth';
import NotificationComponent from './components/NotificationComponent';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = Cookies.get('authToken'); // Замените 'authToken' на имя вашего cookie
    if (token) {
      setIsAuthenticated(true);
    }

    return () => {

    };
  }, []);

  // const applyScale = () => {
  //   console.warn(isMobile)
  //   if (isMobile) {
  //     document.getElementById("main-html").style.transform = "scale(0.5)";
  //     document.getElementById("main-html").style.width = "200%";
  //     document.getElementById("main-html").style.height = "200%";
  //     document.getElementById("main-html").style.transformOrigin = "0 0";
  //   }
  // };

  // useEffect(applyScale);

  return (
    <div className="App">
      <NotificationComponent />
      {isAuthenticated? <Main /> : <Auth />}
    </div>
  );
}

export default App;