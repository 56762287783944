import './NotificationComponent.css';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

const NotificationComponent = forwardRef((props, ref) => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    showNotification(title, text) {
      setTitle(title);
      setText(text);
      setIsVisible(true);
  
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  }));

  return (
    <div className="NotificationComponent notification" ref={ref} style={{ display: isVisible? 'block' : 'none' }}>
      <h1 id="notification-title">{title}</h1>
      <p id="notification-text">{text}</p>
    </div>
  );
});

export default NotificationComponent;