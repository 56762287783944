import "./Settings.css";
import React, { useEffect, useState, useRef } from "react";

import { API_SERVER, STORAGE_SERVER } from "../config.js";

import axios from "axios";
import Cookies from "js-cookie";

import { useNotifications } from "../context/NotificationContext.js";

const Settings = ({ my_user_data, refresh_user_data, add_avatar }) => {
  const { sendNotification } = useNotifications();

  const avatarHiddenInputRef = useRef();

  const [activeSettingsTab, setActiveSettingsTab] = useState("profile");

  const [fieldUsername, setFieldUsername] = useState(
    my_user_data.username || ""
  );

  const [fieldDisplayname, setFieldDisplayname] = useState(
    my_user_data.display_name || ""
  );
  const [fieldDescription, setFieldDescription] = useState(
    my_user_data.description || ""
  );
  const [fieldLocation, setFieldLocation] = useState(
    my_user_data.location || ""
  );

  const [sessionsData, setSessionsData] = useState([]);

  const [userAvatars, setUserAvatars] = useState(() => {
    if (my_user_data.avatars) {
      return my_user_data.avatars.split(";");
    } else {
      return [];
    }
  });

  const switchSettingsTab = (tabId) => {
    setActiveSettingsTab(tabId);
  };

  const handleSaveProfile = async () => {
    const formData = new FormData();
    formData.append("display_name", fieldDisplayname);
    formData.append("description", fieldDescription);
    formData.append("avatars", userAvatars.join(";"));
    formData.append("location", fieldLocation);
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/client/change_profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success change profile");
        refresh_user_data();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleChangeUsername = async () => {
    const formData = new FormData();
    formData.append("username", fieldUsername);
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/client/change_username`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success change username");
        refresh_user_data();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const moveToEnd = (array, element) => {
    const index = element;
    if (index !== -1) {
      const newArray = [...array.slice(0, index), ...array.slice(index + 1)];
      newArray.unshift(array[element]);
      return newArray;
    }
    return array;
  };

  const popAvatars = (array, item) => {
    if (item !== -1 && item < array.length) {
      const newArray = array.filter((_, index) => index !== item);
      return newArray;
    }
    return array;
  };

  const handleMoveToEnd = (item) => {
    const updatedArray = moveToEnd([...userAvatars].reverse(), item);
    setUserAvatars(updatedArray.reverse());
  };

  const handleDeleteAvatar = (item) => {
    const updatedArray = popAvatars([...userAvatars].reverse(), item);
    setUserAvatars(updatedArray.reverse());
  };

  const handleAllSessionClosure = async () => {};

  const handleSessionClosure = async (session_id) => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));
    formData.append("target_session", session_id);
    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/auth/close_session`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success closure");
        refreshSessions();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );

        sendNotification("Ошибка", error.response.data.detail);
      }
    }
  };

  const handleLogout = () => {
    Cookies.remove("authToken");
    window.location.reload();
  };

  const refreshSessions = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/auth/get_sessions`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Sessions update");
        setSessionsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  useEffect(() => {
    if (my_user_data.avatars) {
      setUserAvatars(my_user_data.avatars.split(";"));
    } else {
      setUserAvatars([]);
    }
  }, [my_user_data]);

  useEffect(() => {
    refreshSessions();
  }, []);

  return (
    <div className="Settings settings-navigation-container">
      <div className="settings-navigation-bar">
        <span className="settings-nav-label">Настройки пользователя</span>
        <button
          className="settings-nav-button"
          onClick={() => switchSettingsTab("profile")}
        >
          Профиль
        </button>
        <span className="settings-nav-seperator"></span>
        <span className="settings-nav-label">Безопасность</span>
        <button
          className="settings-nav-button"
          onClick={() => switchSettingsTab("sessions")}
        >
          Сессии
        </button>
        <span className="settings-nav-seperator"></span>
        <span className="settings-nav-label">Другое</span>
        {/* <button className="settings-nav-button" onClick={() => switchSettingsTab('phonk')}>Фонк</button> */}
        <button className="settings-nav-button" onClick={() => handleLogout()}>
          Выйти из учетной записи
        </button>
      </div>

      <div
        className="settings-tab-content"
        style={
          activeSettingsTab === "profile"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="settings-user-container">
          <h2 className="settings-user-title">Пользователь</h2>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Имя пользователя</h4>
            <div className="settings-user-setting-field-and-button">
              <input
                className="kitsunet-design-input-field settings-user-25w"
                type="text"
                placeholder="..."
                value={fieldUsername}
                onChange={(e) => setFieldUsername(e.target.value)}
                required
              />
              <button
                className="kitsunet-design-input-button "
                onClick={handleChangeUsername}
              >
                Сохранить
              </button>
            </div>
          </div>
          <div className="settings-user-setting-seperator"></div>
          <h2 className="settings-user-title">Профиль</h2>
          <button
            className="kitsunet-design-input-button "
            onClick={handleSaveProfile}
          >
            Сохранить изменения профиля
          </button>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Отображаемое имя</h4>
            <input
              className="kitsunet-design-input-field settings-user-25w"
              type="text"
              placeholder="Имя или ник"
              value={fieldDisplayname}
              onChange={(e) => setFieldDisplayname(e.target.value)}
              required
            />
          </div>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Описание профиля</h4>
            <input
              className="kitsunet-design-input-field"
              type="text"
              placeholder="Расскажите что-нибудь о себе"
              value={fieldDescription}
              onChange={(e) => setFieldDescription(e.target.value)}
              required
            />
          </div>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Аватары</h4>
            <div
              className="setting-user-avatars-avatars-container"
              key="avatars-container"
            >
              <div
                className="settings-user-avatars-none-container"
                onClick={() => avatarHiddenInputRef.current.click()}
              >
                <input
                  ref={avatarHiddenInputRef}
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  onChange={add_avatar}
                  style={{ display: "none" }}
                />
                <div className="setting-user-avatars-avatars-avatar-add-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48"
                    viewBox="0 -960 960 960"
                    width="48"
                  >
                    <path
                      fill="#FFFFFF"
                      d="M440-440H240q-17 0-28.5-11.5T200-480q0-17 11.5-28.5T240-520h200v-200q0-17 11.5-28.5T480-760q17 0 28.5 11.5T520-720v200h200q17 0 28.5 11.5T760-480q0 17-11.5 28.5T720-440H520v200q0 17-11.5 28.5T480-200q-17 0-28.5-11.5T440-240v-200Z"
                    />
                  </svg>
                </div>
              </div>
              {[...userAvatars].reverse().map((avatar, index) => (
                <div key={avatar} className="setting-user-avatars-avatars-avatar-container">
                  <img
                    className="setting-user-avatars-avatars-avatar-src"
                    src={`${STORAGE_SERVER}avatars/${avatar}`}
                    alt=""
                    onClick={() => handleMoveToEnd(index)}
                  />
                  <div
                    className="settings-user-avatars-cross"
                    onClick={() => handleDeleteAvatar(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="settings-user-setting-container">
            <h4 className="settings-user-setting-label">Местоположение</h4>
            {/* <h4 className="settings-user-location-location-label"> Cанкт-Петербург, Россия
                            <svg className="settings-user-location-location-label-edit" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  d="M167-120q-21 5-36.5-10.5T120-167l40-191 198 198-191 40Zm191-40L160-358l458-458q23-23 57-23t57 23l84 84q23 23 23 57t-23 57L358-160Zm317-600L261-346l85 85 414-414-85-85Z"/></svg>
                        </h4> */}
            <input
              className="kitsunet-design-input-field settings-user-25w"
              type="text"
              placeholder="Город или страна"
              value={fieldLocation}
              onChange={(e) => setFieldLocation(e.target.value)}
              required
            />
          </div>
        </div>
      </div>

      <div
        className="settings-tab-content"
        style={
          activeSettingsTab === "sessions"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="sessions-container">
          <h3>Ваши активные сессии:</h3>
          {sessionsData
            .filter((session) => session.current)
            .map((session) => (
              <div key={session.id} className="session-container">
                <div>
                  <h3>Текущая сессия</h3>
                  <h4>IP: {session.ip}</h4>
                  <h4>Последняя активность: {session.last_seen}</h4>
                  <h5>id{session.id}</h5>
                </div>
              </div>
            ))}
          <div>
            <button
              className="kitsunet-design-input-button "
              onClick={() => handleAllSessionClosure()}
            >
              Закрыть все остальное сессии
            </button>
          </div>
          {sessionsData
            .filter((session) => !session.current)
            .map((session) => (
              <div key={session.id} className="session-container">
                <div>
                  <h4>IP: {session.ip}</h4>
                  <h4>Последняя активность: {session.last_seen}</h4>
                  <h5>id{session.id}</h5>
                </div>
                <div>
                  <button
                    className="kitsunet-design-input-button "
                    onClick={() => handleSessionClosure(session.id)}
                  >
                    Закрыть сессию
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="settings-tab-content"
        style={
          activeSettingsTab === "logout"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        Выход из аккаунта 𓃥
      </div>
    </div>
  );
};

export default Settings;
