import React from 'react';


const Game = () => {

    return (
        <div className="Game">
            {/* <iframe className='game-iframe' title="level94" src="https://api.kitsunet.ru/storage/hills/index.html" width="960" height="603"></iframe> */}
        </div>
    );
};

export default Game;