import "./Main.css";
import { API_SERVER, STORAGE_SERVER } from "./config.js";

import icon_home from "./assets/images/home.svg";
import HeaderTailIcon from "./assets/images/main-page-tail.svg";
import PersonIcon from "./assets/images/face.svg";
import FriendsIcon from "./assets/images/diversity.svg";
import SearchIcon from "./assets/images/search.svg";
import ChatIcon from "./assets/images/chat.svg";
import DataIcon from "./assets/images/data.svg";
import SettingsIcon from "./assets/images/settings.svg";
import CasinoIcon from "./assets/images/casino.svg";
import GameIcon from "./assets/images/joystick.svg";
import TrafficIcon from "./assets/images/traffic.svg";

import axios from "axios";
import Cookies from "js-cookie";
import { Image } from "image-js";
import React, { useState, useEffect, useRef, act } from "react";

import NotificationComponent from "./components/NotificationComponent.js";
import MediaViewer from "./components/MediaViewer.js";
import ConfirmationDialog from "./components/ConfirmationDialog.js";

import Casino from "./screens/Casino.js";
import Messenger from "./screens/Messenger.jsx";
import Settings from "./screens/Settings.jsx";
import Game from "./screens/Game.js";

import Test from "./screens/Test.js";

import Search from "./screens/Search.js";

import Friends from "./screens/Friends.js";
import MyProfile from "./screens/MyProfile.jsx";
import UserProfile from "./screens/UserProfile.jsx";

function Main() {
  const requestOtherUserPageRef = useRef(null);
  const openChatWithUserRef = useRef();

  const openChatWithUser = (user_id) => {
    switchTab("messenger");
    if (openChatWithUserRef) {
      openChatWithUserRef.current.openChatWithUser(user_id);
    }
  };

  const requestOtherUserPage = (user_id) => {
    if (requestOtherUserPageRef) {
      requestOtherUserPageRef.current.request_user_page(user_id);
      switchTab("other-user");
    }
  };

  const [tasksDailyTicksCooldown, setTasksDailyTicksCooldown] = useState(
    new Date()
  );
  const [dailyLock, setDailyLock] = useState(true);

  const [myUserData, setMyUserData] = useState([]);

  const [friendsRequestsData, setFriendsRequestsData] = useState([]);
  const [friendsData, setFriendsData] = useState([]);

  const [searchResults, setSearchResults] = useState([]);

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "main"
  );
  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    if (activeTab === "other-user") {
      localStorage.setItem("activeTab", "main");
    }
  }, [activeTab]);

  const [isLoading, setIsLoading] = useState(false);

  const switchTab = (tabId) => {
    setActiveTab(tabId);
  };

  const reportUserActivity = async () => {
    try {
      var session_token = Cookies.get("authToken");
      await axios.post(`https://${API_SERVER}/api/v1/client/online_ping`, {
        session_token,
      });
      console.log("Online ping");
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      reportUserActivity();
    }, 10000);

    return () => clearInterval(interval); // будь здорово!!!!!!!!!
  }, []);

  function formatDateTime(isoString) {
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit" };
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("ru-RU", dateOptions);
    const formattedTime = date.toLocaleTimeString("ru-RU", timeOptions);
    return `${formattedDate} в ${formattedTime}`;
  }

  const notificationRef = useRef();
  const mediaViewerRef = useRef();
  const confirmationDialogRef = useRef();

  const avatarHiddenInputRef = useRef();
  const postAddMediaHiddenInputRef = useRef();

  function showNotification(title, text) {
    if (notificationRef.current) {
      notificationRef.current.showNotification(title, text);
    }
  }

  function openMedia(media_url) {
    if (mediaViewerRef.current) {
      mediaViewerRef.current.openMedia(media_url);
    }
  }

  function openDialog(text, target_func) {
    if (confirmationDialogRef.current) {
      confirmationDialogRef.current.showDialog(text, target_func);
    }
  }

  const refresh_all_user_data = async () => {
    refresh_user_main_info();
    refresh_friends();
    refresh_dailies();
  };

  const refresh_user_main_info = async () => {
    try {
      var session_token = Cookies.get("authToken");
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/data/get_my_user`,
        { session_token }
      );
      if (response) {
        console.log("My data update");
        setMyUserData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const refresh_friends = async () => {
    const session_token = Cookies.get("authToken");
    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/data/get_my_friend_requests`,
        { session_token }
      );
      if (response) {
        console.log(`Friend request data update`);
        setFriendsRequestsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }

    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/data/get_my_friends`,
        { session_token }
      );
      if (response) {
        console.log(`Friends data update`);
        setFriendsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const users_search = async (search_str) => {
    try {
      var session_token = Cookies.get("authToken");
      var search_string = search_str;
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/data/search_users`,
        { session_token, search_string }
      );
      if (response) {
        console.log("Search data update");
        setSearchResults(response.data);
      }
    } catch (error) {
      if (error.response) {
        console.warn(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleSearchChange = async (e) => {
    users_search(e.target.value);
  };

  const handleAcceptFriendRequest = async (request_id) => {
    var session_token = Cookies.get("authToken");
    var target_request_id = request_id;
    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/client/accept_friend_request`,
        { session_token, target_request_id }
      );
      if (response) {
        console.log("Success send friend request");
        refresh_friends();
        // setOtherUserPosts(response.data)
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleCollectDailyTicks = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/client/collect_daily_ticks`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success collected ticks");
        setDailyLock(true);
        refresh_dailies();
        refresh_user_main_info();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const refresh_dailies = async () => {
    const formData = new FormData();
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${API_SERVER}/api/v1/data/get_my_daily`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Dailies info update");
        if (response.data.length === 0) {
          setTasksDailyTicksCooldown("2018-06-28T01:05:46.962243");
        } else {
          const date_localTime = new Date(response.data[0].last_collect);
          const mill = date_localTime.getTime();
          setTasksDailyTicksCooldown(mill);
        }
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const calculateDailyTimer = (last_collect) => {
    const now = new Date(Date.now());
    const now_mill = now.getTime();

    const delta_time = now_mill - last_collect;

    const time_left = 86400000 - delta_time;

    if (time_left <= 0) {
      setDailyLock(false);
      return "Уже можно забирать!";
    }

    const hours = Math.floor(time_left / 3600000);
    const minutes = Math.floor((time_left % 3600000) / 60000);
    const seconds = Math.floor((time_left % 60000) / 1000);

    return `Можно забрать через ${hours} часов ${minutes} минут ${seconds} секунд`;
  };

  const handleProfileAvatarClick = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }
    try {
      let arrayBuffer = await file.arrayBuffer();
      let image = await Image.load(arrayBuffer);

      let originalWidth = image.width;
      let originalHeight = image.height;

      let targetSize = Math.min(originalWidth, originalHeight);

      let croppedImage = image.crop({
        x: (originalWidth - targetSize) / 2,
        y: (originalHeight - targetSize) / 2,
        width: targetSize,
        height: targetSize,
      });
      let resized_image = croppedImage.resize({ width: 256, height: 256 });
      const resizedImageBuffer = resized_image.toBuffer();
      const blob = new Blob([resizedImageBuffer], { type: "image/png" });

      const formData = new FormData();
      formData.append("session", Cookies.get("authToken"));
      formData.append("file", blob, "avatar.png");

      axios
        .post(`https://${API_SERVER}/api/v1/client/upload_avatar`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Success avatar upload");
          refresh_user_main_info();
        })
        .catch((error) => {
          console.error("Error:", error.message);
          if (error.response) {
            console.error("Response Status:", error.response.status);
            console.error("Response Data:", error.response.data);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [timeLeft, setTimeLeft] = useState("Можно забрать через...");

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateDailyTimer(tasksDailyTicksCooldown);
      setTimeLeft(newTimeLeft);
    }, 1000); // Обновляем каждую секунду

    // Очищаем таймер при размонтировании компонента
    return () => clearInterval(timer);
  }, [tasksDailyTicksCooldown]);

  useEffect(() => {
    refresh_all_user_data();
    document.querySelector(".phonk-video").volume = 0.05;
  }, []);
  return (
    <div className="Main">
      <div id="index-page-indicator"></div>
      <NotificationComponent ref={notificationRef} />

      <MediaViewer ref={mediaViewerRef} />
      <ConfirmationDialog ref={confirmationDialogRef} />

      {/* <!-- Main block --> */}
      <div className="navigation-container">
        <div className="navigation-bar">
          <div
            className="nav-kitsunet-button"
            onClick={() => switchTab("main")}
          >
            <span>Kitsunet</span>
            <img src={HeaderTailIcon} />
          </div>
          <span className="nav-breaker"></span>
          <div className="nav-buttons-container">
            <div
              className={`nav-button-item nav-light-button ${activeTab === 'settings' ? 'activated-nav-button' : ''}`}
              onClick={() => switchTab("my-profile")}
            >
              <img src={PersonIcon} />
              <h6>Мой профиль</h6>
            </div>
            <div className={`nav-button-item ${activeTab === 'friends' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("friends")}>
              <img src={FriendsIcon} />
              <h6>Друзья</h6>
            </div>
            <div className={`nav-button-item ${activeTab === 'search' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("search")}>
              <img src={SearchIcon} />
              <h6>Поиск</h6>
            </div>
            <div className={`nav-button-item nav-light-button ${activeTab === 'settings' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("settings")}>
              <img src={SettingsIcon} />
              <h6>Настройки</h6>
            </div>
            <div className={`nav-button-item nav-button-long-item ${activeTab === 'messenger' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("messenger")}>
              <img src={ChatIcon} />
              <h6>Мессенджер</h6>
            </div>
            <div className={`nav-button-item  nav-light-button ${activeTab === 'slots' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("slots")}> 
              <img src={CasinoIcon} />
              <h6>Слоты</h6>
            </div>
            <div className={`nav-button-item ${activeTab === 'game' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("game")}>
              <img src={GameIcon} />
              <h6>Игра</h6>
            </div>
            <div className={`nav-button-item ${activeTab === 'phonk' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("phonk")}>
              <img src={TrafficIcon} />
              <h6>Фонк</h6>
            </div>
            <div className={`nav-button-item nav-light-button ${activeTab === 'test' ? 'activated-nav-button' : ''}`} onClick={() => switchTab("test")}>
              <img src={DataIcon} />
              <h6>Тесты</h6>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="tab-content"
          style={
            activeTab === "main" ? { display: "block" } : { display: "none" }
          }
        >
          <div className="main-tab-container">
            <h1>Главное</h1>
            <div className="daily-ticks-container">
              <div className="daily-ticks-container-text">
                <h2>Тики за ежедневный вход</h2>
                <h3>{timeLeft}</h3>
              </div>

              <button
                className="kitsunet-design-input-button"
                onClick={handleCollectDailyTicks}
                disabled={dailyLock}
              >
                Забрать
              </button>
            </div>
            <h2>
              Скоро здесь появиться много нового, за вопросами -{" "}
              <a href="https://t.me/SprintFox">Telegram</a>
            </h2>
            <h2>
              Поддержать копейкой -{" "}
              <a href="https://www.tinkoff.ru/cf/1W2nWFXshSR">Копилка</a>
            </h2>
            <h5 style={{ color: "#ff8800" }}>Kitsunet - v1.2</h5>
          </div>
        </div>

        <div
          id="my-profile"
          className="tab-content"
          style={
            activeTab === "my-profile"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <MyProfile
            my_user_data={myUserData}
            friends_data={friendsData}
            handleProfileAvatarClick={handleProfileAvatarClick}
            switchTab={switchTab}
            request_user_page={requestOtherUserPage}
            openMedia={openMedia}
          ></MyProfile>
        </div>

        <div
          id="test"
          className="tab-content"
          style={
            activeTab === "test" ? { display: "block" } : { display: "none" }
          }
        >
          <Test></Test>
        </div>

        <div
          id="game"
          className="tab-content"
          style={
            activeTab === "game" ? { display: "block" } : { display: "none" }
          }
        >
          <Game></Game>
        </div>

        <div
          id="slots"
          className="tab-content"
          style={
            activeTab === "slots" ? { display: "block" } : { display: "none" }
          }
        >
          <Casino
            balance={myUserData.ticks}
            refresh={refresh_user_main_info}
          ></Casino>
        </div>

        <div
          id="chat"
          className="tab-content"
          style={
            activeTab === "messenger"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Messenger
            ref={openChatWithUserRef}
            my_user_id={myUserData.id}
            openConfirmationDialog={openDialog}
            request_user_page={requestOtherUserPage}
          ></Messenger>
        </div>

        <div
          id="search"
          className="tab-content"
          style={
            activeTab === "search" ? { display: "block" } : { display: "none" }
          }
        >
          <Search
            searchResults={searchResults}
            handleSearchChange={handleSearchChange}
            request_page={requestOtherUserPage}
            switch_tab={switchTab}
          ></Search>
        </div>

        <div
          id="phonk"
          className="tab-content"
          style={
            activeTab === "phonk" ? { display: "block" } : { display: "none" }
          }
        >
          <video className="phonk-video" controls>
            <source src="https://api.kitsunet.ru/phonk" type="audio/mpeg" />
            Your browser does not support the audio element.
          </video>
          <h1>NIGHT DRIVE PHONK MIX (LXST CXNTURY TYPE) #3 - PHONKONAUT</h1>
        </div>
        {/* 
        
          OTHER PROFILE

        */}
        <div
          id="other-user"
          className="tab-content"
          style={
            activeTab === "other-user"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <UserProfile
            ref={requestOtherUserPageRef}
            openMedia={openMedia}
            friendsRequestsData={friendsRequestsData}
            friendsData={friendsData}
            refresh_friends={refresh_friends}
            openChatWithUser={openChatWithUser}
          />
        </div>
        <div
          id="friends"
          className="tab-content"
          style={
            activeTab === "friends" ? { display: "block" } : { display: "none" }
          }
        >
          <Friends
            friendsData={friendsData}
            friendsRequestsData={friendsRequestsData}
            switch_tab={switchTab}
            request_page={requestOtherUserPage}
            handleAcceptFriendRequest={handleAcceptFriendRequest}
          />
        </div>

        <div
          id="settings"
          className="tab-content"
          style={
            activeTab === "settings"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {myUserData.length !== 0 && (
            <Settings
              my_user_data={myUserData}
              refresh_user_data={refresh_user_main_info}
              add_avatar={handleProfileAvatarClick}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Main;
