import React, { createContext, useContext, useState } from 'react';

const OptionsMenuContext = createContext();

export const useOptionsMenu = () => useContext(OptionsMenuContext);

export const OptionsMenuProvider = ({ children }) => {
    const [text, setText] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [callbackFunc, setCallbackFunc] = useState(null);

    const showDialog = (text, callback_func) => {
        setText(text);
        setIsVisible(true);
        setCallbackFunc(() => { return callback_func })
    };

    const handleConfirmButton = () => {
        callbackFunc();
        setIsVisible(false);
    };

    const handleCancelButton = () => {
        setCallbackFunc(null);
        setIsVisible(false);
    };

    return (
        <OptionsMenuContext.Provider value={{ text, isVisible, showDialog, handleConfirmButton, handleCancelButton }}>
            {children}
        </OptionsMenuContext.Provider>
    );
};